export const environment = {
    production: true,
    dxgridPageSize: 10, // default page size of table
    allowedPageSizes: [10, 25, 50, 100],
    resultCodeSuccess: '20000',
    resultCodeMissingParameter: '40300',
    resultDescriptionMissingParameterTitle: 'Missing or invalid parameter !',
    resultDescriptionMissingParameterMassage: 'Please contact your administrator.',
    resultCodeDataExisted: '40301',
    resultDescriptionDataExistedTitle: 'Data existed !',
    resultDescriptionDataExistedMassage: 'Please check your data and try again.',
    resultCodeAdditionalClosingTimeError: '40307',
    resultCodeDataNotFound: '40400',
    resultDescriptionDataNotFoundTitle: 'Data not found ! ',
    resultDescriptionDataNotFoundMassage: 'Pleaes check your data and try again.',
    resultCodeSystemError: '50000',
    resultDescriptionSystemErrorTitle: 'System Error !',
    resultDescriptionSystemErrorMassage: 'Please contact your administrator.',
    resultCodeDbError: '50001',
    resultDescriptionDbErrorTitle: 'Error Connecting to Database !',
    resultDescriptionDbErrorMassage: 'Please contact your administrator.',
    resultCodeDeleteDataAtHaveChild: '50002',
    resultDescriptionDeleteDataAtHaveChildTitle: 'Error !',
    resultDescriptionDeleteDataAtHaveChildMassage: 'Please check menu or group before delete this microservice.',
    fieldsMicroservicesList: 'menuId,menuName,updatedAt',
    fieldsGroupsList: 'menuId,roleId,roleName,updatedAt',
    fieldsMenusList: 'subMenuParentId,subMenuId,subMenuName,url,description,menuIcon,newTap',
    fieldsUsersList: 'userId,username,firstname,lastname,email,telephoneNumber,position,admin',
    ip: 'https://vtl-supplementary-be-hotfix-be.scapp.work', // http://vtl-supplementary-be-hotfix-be.scapp.work
    ipSocket: 'https://vtl-supplementary-be-hotfix-be.scapp.work', // http://vtl-supplementary-be-hotfix-be.scapp.work
    firebase: {
        apiKey: 'AIzaSyDfjx4k9p_UqB9eDRYnsfvGAcKvCrumMj0',
        authDomain: 'vitallife-2e2a0.firebaseapp.com',
        projectId: 'vitallife-2e2a0',
        storageBucket: 'vitallife-2e2a0.appspot.com',
        messagingSenderId: '124209135410',
        appId: '1:124209135410:web:3786dd60164d796bffe12b',
        measurementId: 'G-4M1WZJTJ9X',
        vapidKey: 'BIPYrOc2BUq8hE-_U6BpfuZHco6rD-QLXpDGTDlnXTquuAZucV-6hkcgkyt-WfbPqgvKsbA0hAQb-0qlh2Np97Q'
    },
    apiPrefix: '/api/v1/bih',
    searchUsers: '/user/searchUsers',
    createUser: '/user/createUser',
    searchUserById: '/user/searchUserById',
    updateUser: '/user/updateUser',
    deleteUser: '/user/deleteUser',
    searchUserPermission: '/assignRolePermission/searchUserPermission',
    searchRoleMenus: '/assignRolePermission/searchRoleMenus',
    createAssignRolePermission: '/assignRolePermission/createAssignRolePermission',
    searchRoles: '/roles/searchRoles',
    createRole: '/roles/createRole',
    updateRole: '/roles/updateRole',
    deleteRole: '/roles/deleteRole',
    searchMenus: '/menus/searchMenus',
    createMenu: '/menus/createMenu',
    updateMenu: '/menus/updateMenu',
    deleteMenu: '/menus/deleteMenu',
    searchSubmenus: '/menus/searchSubmenus',
    rolesSearchRoleMenu: '/roles/searchRoleMenus',
    createRoleMenu: '/roles/createRoleMenu',
    updateRoleMenu: '/roles/updateRoleMenu',
    roleURL: {
        user: '/user',
        microservice: '/assign-microservice-permission',
        roles: '/roles',
        subMenus: '/microservice-menus',
        order: '/order-management/all-orders',
        pharmacistView : '/order-management/orders-pharmacist-view',
        cashierView : '/order-management/orders-cashier-view',

    },
    searchProductionSchedule: '/productionSchedule/searchProductionSchedule',
    updateProductionSchedule: '/productionSchedule/updateProductionSchedule',
    searchProductionTime: '/productionSchedule/searchProductionTime',
    updateProductionTime: '/productionSchedule/updateProductionTime',
    searchAdditionalProdSchedule: '/productionSchedule/searchAdditionalProdSchedule',
    searchAdditionalProdSchedules: '/productionSchedule/searchAdditionalProdSchedules',
    updateAdditionalProdSchedule: '/productionSchedule/updateAdditionalProdSchedule',
    searchConfig: '/config/searchConfig',
    searchOrderCashierView: '/orders/searchOrderCashierView',
    searchOrderCashierViewBooking: '/orders/searchOrderCashierViewBooking',
    searchOrderCashierViewFinishedProduct: '/orders/searchOrderCashierViewFinishedProduct',
    searchOrders: '/orders/searchOrders',
    searchOrderCS: '/orders/searchOrderCS',
    searchOrderById: '/orders/searchOrderById',
    searchAllBooking: '/orders/searchAllBooking',
    updateStatusCashier: '/orders/updateStatusCashier',
    export: '/export',
    searchDdlPatientDelivery: '/ddl/searchDdlPatientDelivery',
    createOrderFinishedProduct: '/orders/createOrderFinishedProduct',
    updateDeliveryDetail: '/orders/updateDeliveryDetail',
    searchDdl: '/ddl',
    searchPatientBooking: '/orders/searchPatientBooking',
    searchDdlPatients: '/ddl/searchDdlPatients',
    searchDdlPatientReserve: '/ddl/searchDdlPatientReserve',
    createReservation: '/orders/createReservation',
    searchReservation: '/orders/searchReservation',
    searchReservationById: '/orders/searchReservationById',
    updateCancelReservation: '/orders/updateCancelReservation',
    printDeliveryDetail: '/print/printDeliveryDetail',
    printDeliveryForm: '/print/printDeliveryForm',
    printSupplementDetail: '/print/printSupplementDetail',
    searchDdlCashier: '/ddl/searchDdlCashier',
    searchDdlPatientAddress: '/ddl/searchDdlPatientAddress',
    printSMLLabel: '/print/printSMLLabel',
    searchBookingCalendar: '/orders/searchBookingCalendar',
    createBooking: '/orders/createBooking',
    updateChangeBooking: '/orders/updateChangeBooking',
    createUrgentRequest: '/orders/createUrgentRequest',
    searchCountItem: '/orders/searchCountItem',
    printProductionSheet: '/print/printProductionSheet',
    printLabel: '/print/printLabel',
    printWorkingFormula: '/print/printWorkingFormula',
    printWorkingFormula2: '/print/printWorkingFormula2',
    printPatientInformation: '/print/printPatientInfo',
    printSupplementInformation: '/print/printSupplementInfo',
    printRMChargeDetail: '/print/printRMChargeDetail',
    printLabelSachet: '/print/printLabelSachet',
    printSupplementFacts: '/print/printSupplementFacts',
    searchRawMaterials: '/rawMaterials/searchRawMaterials',
    searchPharmacyNotes: '/pharmacyNotes/searchPharmacyNotes',
    searchCapsules: '/capsules/searchCapsules',
    searchErrorOrder: '/orders/searchErrorOrder',
    updateErrorOrderStatus: '/orders/updateErrorOrderStatus',
    updateWorkingBy: '/orders/updateWorkingBy',
    searchDeliveryDetail: '/orders/searchDeliveryDetail',
    searchQuotationDetail: '/orders/searchQuotationDetail',
    printAll: '/print/printAll',
    ordersInactive: '/orders/ordersInactive',
    searchRunningPatient: '/patients/searchRunningPatient',
    searchDdlLocationDetail: '/ddl/searchDdlLocationDetail',
    getPatientTemplate: '/patients/getPatientTemplate',
    clinicsList: ['Samphran Clinics', 'SC Clinics']
};
