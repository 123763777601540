import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
  EventEmitter,
  Output,
  NgModule
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { DxDataGridComponent, DxDataGridModule, DxFileUploaderModule } from 'devextreme-angular';
import { environment } from '../../environments/environment';

declare let $: any;
import { FormsModule } from '@angular/forms';
import CustomStore from 'devextreme/data/custom_store';
import { Request } from '../shared/services/request.service';
import { Common } from '../shared/services/common.service';
import { NgxPrintModule } from 'ngx-print';
import heic2any from 'heic2any';
import { DxLoadPanelModule} from 'devextreme-angular';

class ImageSnippet {
  pending = false;
  status = 'init';

  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'app-alert',
  providers: [],
  templateUrl: './alert.component.html'
})
export class CreateAlertComponent implements OnInit, AfterViewInit {

  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;
  @ViewChild('gridExport') gridExport: DxDataGridComponent;
  @ViewChild('gridAlertValidExcel') gridAlertValidExcel: DxDataGridComponent;
  @ViewChild('gridAlertErrorExcel') gridAlertErrorExcel: DxDataGridComponent;
  @ViewChild('gridAdvance') gridAdvance: DxDataGridComponent;
  @ViewChild('myModalSuccess') modalSuccess: ElementRef;
  @ViewChild('myModalSuccessUser') modalSuccessUser: ElementRef;

  @ViewChild('myModalSuccessWarning') modalSuccessWarning: ElementRef;

  @ViewChild('myModalDelete') modalDelete: ElementRef;
  @ViewChild('myModalChangeBooking') modalChangeBooking: ElementRef;
  @ViewChild('myModalConfirmManPower') modalConfirmManPower: ElementRef;
  @ViewChild('myModalDeleteWarning') modalDeleteWarning: ElementRef;
  @ViewChild('myModalSuccessDelete') modalSuccessDelete: ElementRef;
  @ViewChild('myModalEditSuccess') modalEditSuccess: ElementRef;
  @ViewChild('myModalWarning') modalWarning: ElementRef;
  @ViewChild('myModalInsertTrakCare') modalInsertTrakCare: ElementRef;
  @ViewChild('myModalCompleteDelivery') modalCompleteDelivery: ElementRef;


  @ViewChild('myModalError') modalError: ElementRef;
  @ViewChild('myModalErrorImport') modalErrorImport: ElementRef;
  @ViewChild('myModalViewWarning') modalViewWarning: ElementRef;
  @ViewChild('myModalProgressBarWarning') modalProgressBarWarning: ElementRef;
  @ViewChild('myModalUploadImageWarning') modalUploadImageWarning: ElementRef;
  @ViewChild('myModalUploadImageTypeWarning') modalUploadImageTypeWarning: ElementRef;

  @ViewChild('myModalWarningNoTextSearch') modalWarningNoTextSearch: ElementRef;
  @ViewChild('myModalErrorDataExisted') modalErrorDataExisted: ElementRef;
  @ViewChild('myModalErrorDeleteButHaveMenuGroup') modalErrorDeleteButHaveMenuGroup: ElementRef;
  @ViewChild('myModalConfirmReOrder') modalConfirmReOrder: ElementRef;
  @ViewChild('myModalConfirmUrgentRequest') modalConfirmUrgentRequest: ElementRef;
  @ViewChild('myModalUrgentRequest') modalUrgentRequest: ElementRef;
  @ViewChild('myModalPrintDocument') modalPrintDocument: ElementRef;
  @ViewChild('myModalPrintDocument2') modalPrintDocument2: ElementRef;
  @ViewChild('myModalConfirm') modalConfirm: ElementRef;
  @ViewChild('myModalConfirmProduction') modalConfirmProduction: ElementRef;
  @ViewChild('myModalConfirmProductionDone') modalConfirmProductionDone: ElementRef;
  @ViewChild('myModalOrderError') modalOrderError: ElementRef;
  @ViewChild('myModalOrderErrorStatus1') modalOrderErrorStatus1: ElementRef;
  @ViewChild('myModalOrderInactive') modalOrderInactive: ElementRef;

  userMessage;
  userMessageList;
  dataText: any;
  dxgridPageSize;
  failDescription;
  percent = 0;
  buttonText = '';
  data: any = {};
  urgentRequestReason = '';
  urgentRequestForm: FormGroup;
  orderInactiveForm: FormGroup;
  orderInactiveRemark = '';
  printPageList = [];
  isShowPrintLabel = false;
  printOrderId = null;
  iconURL = '../../assets/icon-md/calendar.png';
  selectedFile: any;
  filePicture: any;
  imageInputText: string;
  productionNote: any;
  packageNote: any;
  imageList = [];
  productionDoneForm: FormGroup;
  submitted: boolean = false;
  printAllurl = null
  isLoadingPanel: boolean = false;

  constructor(private router: Router, private request: Request, private common: Common, private fb: FormBuilder) {

    this.dataText = {
      userTitle: '',
      userMessage: '',
      userMessageList: ''
    };
    this.urgentRequestForm = this.fb.group({
      'txtUrgentRequestReason': new FormControl('', [Validators.required]),
    });
    this.orderInactiveForm = this.fb.group({
      'txtOrderInactiveRemark': new FormControl('', [Validators.required]),
    });

    this.productionDoneForm = this.fb.group({
      'txtProductionNote': new FormControl(''),
      'txtPackageNote': new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
  }

  isNumber(str) {
    return !isNaN(str);
  }

  async ngAfterViewInit() {
    try {
      $(this.modalSuccess.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here

        this.onClickBack.emit();
      });

      $(this.modalEditSuccess.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here
        this.onClickBack.emit();
      });
      $(this.modalSuccessDelete.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here
        this.onClickBack.emit();
      });

      $(this.modalError.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here
        // await this.router.navigate([this.urlBack]);
        this.onCloseModalError.emit();
      });

      $(this.modalErrorImport.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here
        // await this.router.navigate([this.urlBack]);
        this.onCloseModalErrorImport.emit();
      });

      $(this.modalWarning.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here
        // await this.router.navigate([this.urlBack]);
        this.onCloseModalWarning.emit();
      });

      $(this.modalViewWarning.nativeElement).on('hidden.bs.modal', async () => {
        // put your default event here
        // await this.router.navigate([this.urlBack]);
        this.onCancelViewWarning.emit();
      });

      // ปรับ html <body> ให้กลับเป็นปกติเมื่อเปิด-ปิด modal
      // document.body.style.paddingRight = '0px';
    } catch (e) {
      console.log(e);
    }
  }

  openModal(data) {
    // console.log(this.elementRef.nativeElement.querySelector('#myModalDelete'));
    console.log(data);
    // this.userMessage = userMessage;
    // let data =
    // if(){
    //
    // }
    // ถ้าเป็น myModalExportLoading จะโยน data มาต่างกัน
    // if (data.modalId === 'myModalExportLoading') {
    //   this.dataExport = data.userMessage ? data.userMessage.dataExport : [];
    //   this.buttonText = data.userMessage ? data.userMessage.buttonText : 'PDF';
    // } else {
    this.dataText = {
      userTitle: data.userTitle,
      userMessage: data.userMessage,
      userMessageList: data.userMessageList
    };
    // }


    if (data.modalId === 'myModalDelete') {
      $(this.modalDelete.nativeElement).modal('show');
    } else if (data.modalId === 'myModalConfirmManPower') {
      if (data.data) {
        this.data = data.data;
        if (!this.data.manPowerFrom) {
          this.data.manPowerFrom = 0;
        }
      }
      $(this.modalConfirmManPower.nativeElement).modal('show');
    } else if (data.modalId === 'myModalDeleteWarning') {
      $(this.modalDeleteWarning.nativeElement).modal('show');
    } else if (data.modalId === 'myModalSuccessDelete') {
      $(this.modalSuccessDelete.nativeElement).modal('show');
    } else if (data.modalId === 'myModalSuccess') {
      $(this.modalSuccess.nativeElement).modal('show');
    } else if (data.modalId === 'myModalSuccessUser') {
      $(this.modalSuccessUser.nativeElement).modal('show');
    } else if (data.modalId === 'myModalSuccessWarning') {
      $(this.modalSuccessWarning.nativeElement).modal('show');
    } else if (data.modalId === 'myModalEditSuccess') {
      $(this.modalEditSuccess.nativeElement).modal('show');
    } else if (data.modalId === 'myModalWarning') {
      $(this.modalWarning.nativeElement).modal('show');
    } else if (data.modalId === 'myModalViewWarning') {
      $(this.modalViewWarning.nativeElement).modal('show');
    } else if (data.modalId === 'myModalUploadImageWarning') {
      $(this.modalUploadImageWarning.nativeElement).modal('show');
    } else if (data.modalId === 'myModalUploadImageTypeWarning') {
      $(this.modalUploadImageTypeWarning.nativeElement).modal('show');
    } else if (data.modalId === 'myModalWarningNoTextSearch') {
      $(this.modalWarningNoTextSearch.nativeElement).modal('show');
    } else if (data.modalId === 'myModalErrorDataExisted') {
      $(this.modalErrorDataExisted.nativeElement).modal('show');
    } else if (data.modalId === 'myModalErrorDeleteButHaveMenuGroup') {
      $(this.modalErrorDeleteButHaveMenuGroup.nativeElement).modal('show');
    } else if (data.modalId === 'modalError') {
      $(this.modalError.nativeElement).modal('show');
    } else if (data.modalId === 'modalErrorImport') {
      $(this.modalErrorImport.nativeElement).modal('show');
    } else if (data.modalId === 'myModalConfirmReOrder') {
      $(this.modalConfirmReOrder.nativeElement).modal('show');
    } else if (data.modalId === 'myModalConfirmUrgentRequest') {
      if (this.dataText.userMessageList)
        this.urgentRequestReason = this.dataText.userMessageList
      $(this.modalConfirmUrgentRequest.nativeElement).modal('show');
    } else if (data.modalId === 'myModalUrgentRequest') {
      this.urgentRequestForm.controls[`txtUrgentRequestReason`].reset();
      $(this.modalUrgentRequest.nativeElement).modal('show');
    } else if (data.modalId === 'myModalPrintDocument') {
      if (data.data) {
        this.printPageList = data.data.printPageList;
        this.isShowPrintLabel = data.data.isShowPrintLabel;
        this.printOrderId = data.data.orderId;
      }
      $(this.modalPrintDocument.nativeElement).modal({
        backdrop: 'static'
      });
    } else if (data.modalId === 'myModalPrintDocument2') {
      if (data.data) {
        this.printPageList = data.data.printPageList;
        this.isShowPrintLabel = data.data.isShowPrintLabel;
        this.printOrderId = data.data.orderId;
        this.printAllurl = data.data.printAllurl
      }
      $(this.modalPrintDocument2.nativeElement).modal({
        backdrop: 'static'
      });
    } else if (data.modalId === 'myModalConfirm') {
      if (data.data) {
        this.iconURL = data.data.iconURL;
      }
      $(this.modalConfirm.nativeElement).modal('show');
    } else if (data.modalId === 'myModalConfirmProduction') {
      $(this.modalConfirmProduction.nativeElement).modal('show');
    } else if (data.modalId === 'myModalConfirmProductionDone') {
      this.submitted = false
      this.imageList = [];
      this.packageNote = ''
      this.productionNote = ''
      this.filePicture = null
      this.imageInputText = ''
      this.productionDoneForm.controls[`txtProductionNote`].reset();
      this.productionDoneForm.controls[`txtPackageNote`].reset();
      $(this.modalConfirmProductionDone.nativeElement).modal({
        backdrop: 'static'
      });
    } else if (data.modalId === 'myModalChangeBooking') {
      $(this.modalChangeBooking.nativeElement).modal('show');
    } else if (data.modalId === 'myModalInsertTrakCare') {
      $(this.modalInsertTrakCare.nativeElement).modal('show');
    } else if (data.modalId === 'myModalCompleteDelivery') {
      $(this.modalCompleteDelivery.nativeElement).modal('show');
    } else if (data.modalId === 'myModalOrderError') {
      this.dataText.userMessageList = this.dataText.userMessage.split('\n')
      $(this.modalOrderError.nativeElement).modal({
        backdrop: 'static'
      });
    } else if (data.modalId === 'myModalOrderErrorStatus1') {
      this.dataText.userMessageList = this.dataText.userMessage.split('\n')
      $(this.modalOrderErrorStatus1.nativeElement).modal({
        backdrop: 'static'
      });
    } else if (data.modalId === 'myModalOrderInactive') {
      this.orderInactiveForm.controls['txtOrderInactiveRemark'].reset();
      if (this.dataText.userMessageList) {
        this.orderInactiveRemark = this.dataText.userMessageList;
      }
      $(this.modalOrderInactive.nativeElement).modal('show');
    } else {
      $(this.modalError.nativeElement).modal('show');
    }

    // ปรับ html <body> ให้กลับเป็นปกติเมื่อเปิด-ปิด modal
    document.body.style.paddingRight = '0px';
  }

  closeModal(modalId) {
    if (modalId === 'myModalDelete') {
      $(this.modalDelete.nativeElement).modal('hide');
    } else if (modalId === 'myModalConfirmManPower') {
      $(this.modalConfirmManPower.nativeElement).modal('hide');
    } else if (modalId === 'myModalDeleteWarning') {
      $(this.modalDeleteWarning.nativeElement).modal('hide');
    } else if (modalId === 'myModalSuccessDelete') {
      $(this.modalSuccessDelete.nativeElement).modal('hide');

    } else if (modalId === 'myModalSuccess') {
      $(this.modalSuccess.nativeElement).modal('hide');

    } else if (modalId === 'myModalSuccessUser') {
      $(this.modalSuccessUser.nativeElement).modal('hide');

    } else if (modalId === 'myModalSuccessWarning') {
      $(this.modalSuccessWarning.nativeElement).modal('hide');
    } else if (modalId === 'myModalEditSuccess') {
      $(this.modalEditSuccess.nativeElement).modal('hide');
    } else if (modalId === 'myModalWarning') {
      $(this.modalWarning.nativeElement).modal('hide');
    } else if (modalId === 'myModalViewWarning') {
      $(this.modalViewWarning.nativeElement).modal('hide');
    } else if (modalId === 'myModalUploadImageWarning') {
      $(this.modalUploadImageWarning.nativeElement).modal('hide');
    } else if (modalId === 'myModalUploadImageTypeWarning') {
      $(this.modalUploadImageTypeWarning.nativeElement).modal('hide');
    } else if (modalId === 'myModalWarningNoTextSearch') {
      $(this.modalWarningNoTextSearch.nativeElement).modal('hide');
    } else if (modalId === 'myModalErrorDataExisted') {
      $(this.modalErrorDataExisted.nativeElement).modal('hide');
    } else if (modalId === 'myModalErrorDeleteButHaveMenuGroup') {
      $(this.modalErrorDeleteButHaveMenuGroup.nativeElement).modal('hide');
    } else if (modalId === 'modalErrorImport') {
      $(this.modalErrorImport.nativeElement).modal('hide');
    } else if (modalId === 'myModalConfirmUrgentRequest') {
      $(this.modalConfirmUrgentRequest.nativeElement).modal('hide');
    } else if (modalId === 'myModalUrgentRequest') {
      $(this.modalUrgentRequest.nativeElement).modal('hide');
    } else if (modalId === 'myModalPrintDocument') {
      $(this.modalPrintDocument.nativeElement).modal('hide');
    } else if (modalId === 'myModalConfirm') {
      $(this.modalConfirm.nativeElement).modal('hide');
    } else if (modalId === 'myModalProduction') {
      $(this.modalConfirmProduction.nativeElement).modal('hide');
    } else if (modalId === 'myModalProductionDone') {
      $(this.modalConfirmProductionDone.nativeElement).modal('hide');
    } else if (modalId === 'myModalChangeBooking') {
      $(this.modalChangeBooking.nativeElement).modal('hide');
    } else if (modalId === 'myModalInsertTrakCare') {
      $(this.modalInsertTrakCare.nativeElement).modal('hide');
    } else if (modalId === 'myModalCompleteDelivery') {
      $(this.modalCompleteDelivery.nativeElement).modal('hide');
    } else if (modalId === 'myModalConfirmProductionDone') {
      this.submitted = false
      this.imageList = [];
      $(this.modalConfirmProductionDone.nativeElement).modal('hide');
    } else if (modalId === 'myModalOrderError') {
      $(this.modalOrderError.nativeElement).modal('hide');
    } else if (modalId === 'myModalOrderErrorStatus1') {
      $(this.modalOrderError.nativeElement).modal('hide');
    } else if (modalId === 'myModalOrderInactive') {
      $(this.modalOrderInactive.nativeElement).modal('hide');
    } else {
      console.error('closeModal Error');
    }
  }

  goBack() {
    this.onClickBack.emit();
  }

  showStringValidDataExcel(data) {
    let text = '';
    if (data.data && data.data.itemOjb && data.data.itemOjb.failDescription) {
      for (let j = 0; j < data.data.itemOjb.failDescription.length; j++) {
        text += '[' + data.data.itemOjb.failDescription[j].columeName + ']' + ':"' + data.data.itemOjb.failDescription[j].text + '", <br/>';
      }
    }
    return text;
  }

  goAssignRolePermission() {
    console.log(this.dataText.userTitle);
    this.router.navigate(['/assign-microservice-permission', this.dataText.userTitle, 'assign-microservice-permission-info']);
  }

  showStringErrorDataExcel(failDescription) {
    let text = '';
    for (let j = 0; j < failDescription.length; j++) {
      text += '[' + failDescription[j].columeName + ']' + ':"' + failDescription[j].text + '", <br/>';
    }
    return text;
  }

  closeProgressbar() {
    $(this.modalProgressBarWarning.nativeElement).modal('hide');
  }

  @Output() onCloseModalError = new EventEmitter();
  @Output() onCloseModalErrorImport = new EventEmitter();
  @Output() onOkDelete = new EventEmitter();
  @Output() onCancelDelete = new EventEmitter();
  @Output() onClickBack = new EventEmitter();
  @Output() onCancelViewWarning = new EventEmitter();
  @Output() onCancelUploadValid = new EventEmitter();
  @Output() onDataErrorExcel = new EventEmitter();
  @Output() onClickExport = new EventEmitter();
  @Output() onClickDeleteExport = new EventEmitter();
  @Output() onOkClone = new EventEmitter();
  @Output() onCancelClone = new EventEmitter();
  @Output() onOkDiagramCancel = new EventEmitter();
  @Output() onCancelDiagramCancel = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onCloseModalWarning = new EventEmitter();
  @Output() onDownload = new EventEmitter();
  @Output() onOkManPowerModal = new EventEmitter();
  @Output() onOkReOrderModal = new EventEmitter();
  @Output() onClickApproveModal = new EventEmitter();
  @Output() onCancelUrgentRequest = new EventEmitter();
  @Output() onConfirmUrgentRequest = new EventEmitter();
  @Output() onCancelOrderInactive = new EventEmitter();
  @Output() onOKOrderInactive = new EventEmitter();
  @Output() onOk = new EventEmitter();
  @Output() onOkProductionModal = new EventEmitter
  @Output() onOkProductionDoneModal = new EventEmitter
  @Output() onOkSuccess = new EventEmitter
  @Output() onOkChangeBooking = new EventEmitter();
  @Output() onCancelChangeBooking = new EventEmitter();
  @Output() onCancelInsertTrakCare = new EventEmitter();
  @Output() onOkInsertTrakCare = new EventEmitter();
  @Output() onOkCompleteDelivery = new EventEmitter();
  @Output() onCancelCompleteDelivery = new EventEmitter();
  @Output() onClosePrintDocument = new EventEmitter();
  @Output() onClickRejectModal = new EventEmitter();
  @Output() onClickEditOrder = new EventEmitter();
  @Output() onCloseEditOrder = new EventEmitter();

  onClickEditOrderModal() {
    $(this.modalOrderError.nativeElement).modal('hide');
    this.onClickEditOrder.emit();
  }

  onCloseEditOrderModal() {
    $(this.modalOrderError.nativeElement).modal('hide');
    this.onCloseEditOrder.emit();
  }

  onOkDeleteModal() {
    $(this.modalDelete.nativeElement).modal('hide');
    this.onOkDelete.emit();
  }

  onCancelDeleteModal() {
    this.onCancelDelete.emit();
  }

  onOkChangeBookingModal() {
    $(this.modalChangeBooking.nativeElement).modal('hide');
    this.onOkChangeBooking.emit();
  }

  onCancelChangeBookingModal() {
    this.onCancelChangeBooking.emit();
  }

  onOkInsertTrakCareModal() {
    $(this.modalInsertTrakCare.nativeElement).modal('hide');
    this.onOkInsertTrakCare.emit();
  }

  onCancelInsertTrakCareModal() {
    this.onCancelInsertTrakCare.emit();
  }

  onCancelCompleteDeliveryModal() {
    this.onCancelCompleteDelivery.emit();
  }

  onOkCompleteDeliveryModal() {
    $(this.modalCompleteDelivery.nativeElement).modal('hide');
    this.onOkCompleteDelivery.emit();
  }

  onClickBackModal() {
    this.onClickBack.emit();
  }

  onClickReject() {
    this.onClickRejectModal.emit();
  }

  onClickBackProductionDoneModal() {
    const valid = this.checkRequiredProductionDone();
    this.submitted = true
    if (valid) {
      $(this.modalConfirmProductionDone.nativeElement).modal('hide');
    }
  }

  onCancelViewWarningModal() {
    console.log('hjh');
    this.onCancelViewWarning.emit();
  }

  onCancelUploadValidModal() {
    this.onCancelUploadValid.emit();
  }

  onDataErrorExcelModal() {
    this.onDataErrorExcel.emit();
  }

  onCloseModalErrorModal() {
    this.onCloseModalError.emit();
  }

  onCloseModalWarningModal() {
    this.onCloseModalWarning.emit();
  }

  onCancelCloneModal() {
    this.onCancelClone.emit();
  }

  onCancelDiagramCancelModal() {
    this.onCancelDiagramCancel.emit();
  }

  onDownloadModal() {
    this.onDownload.emit();
  }

  onClickOkManPower() {
    this.onOkManPowerModal.emit();
  }

  onClickOkReOrder() {
    this.closeModal('myModalConfirmReOrder');
    this.onOkReOrderModal.emit();
  }

  onClickApprove() {
    this.onClickApproveModal.emit();
  }

  onClickCancelUrgentRequest() {
    this.onCancelUrgentRequest.emit();
  }

  onClickConfirmUrgentRequest() {
    const valid = this.checkRequiredData();
    if (valid) {
      this.onConfirmUrgentRequest.emit(this.urgentRequestReason);
      $(this.modalUrgentRequest.nativeElement).modal('hide');
    }
  }

  onClickCancelOrderInactive() {
    this.onCancelOrderInactive.emit();
  }

  onClickOKOrderInactive() {
    const valid = this.checkRequiredOrderInactive();
    if (valid) {
      this.onOKOrderInactive.emit(this.orderInactiveRemark);
      // $(this.modalOrderInactive.nativeElement).modal('hide');
    }
  }

  onClickOk() {
    this.onOk.emit();
  }

  onClickCancel() {
    this.onClose.emit();
  }

  onClickOkProduction() {
    this.onOkProductionModal.emit();
  }

  async onClickOkProductionDone() {
    const valid = this.checkRequiredProductionDone();
    this.submitted = true
    if (valid) {
      const imageListBase64 = [];
      for (let i = 0; i < this.imageList.length; i++) {
        const base64 = await this.toBase64(this.imageList[i]);
        imageListBase64.push(base64);
      }
      const data = {
        productionNote: this.productionNote,
        packageNote: this.packageNote,
        productionPicture: imageListBase64.join('|')
      };
      this.onOkProductionDoneModal.emit(data);
      $(this.modalConfirmProductionDone.nativeElement).modal('hide');
    }

  }

  onClickOkSuccess() {
    $(this.modalSuccess.nativeElement).modal('hide');
    this.onOkSuccess.emit();
  }

  onClickClosePrintDocument() {
    $(this.modalPrintDocument.nativeElement).modal('hide');
    this.onClosePrintDocument.emit();
  }

  onClickClosePrintDocument2() {
    $(this.modalPrintDocument2.nativeElement).modal('hide');
    this.onClosePrintDocument.emit();
  }


  checkRequiredData() {
    console.log(this.urgentRequestForm.controls, this.urgentRequestForm, this.urgentRequestForm.valid);
    for (const key in this.urgentRequestForm.controls) {
      if (this.urgentRequestForm.controls[key].errors) {
        this.urgentRequestForm.controls[key].setErrors({ 'forceRequired': true });
        this.urgentRequestForm.controls[key].markAsDirty();
      } else {
        this.urgentRequestForm.controls[key].updateValueAndValidity();
      }
    }
    return this.urgentRequestForm.valid;
  }

  checkRequiredProductionDone() {
    console.log(this.productionDoneForm.controls, this.productionDoneForm, this.productionDoneForm.valid);
    for (const key in this.productionDoneForm.controls) {
      if (this.productionDoneForm.controls[key].errors) {
        this.productionDoneForm.controls[key].setErrors({ 'forceRequired': true });
        this.productionDoneForm.controls[key].markAsDirty();
      } else {
        this.productionDoneForm.controls[key].updateValueAndValidity();
      }
    }
    return this.productionDoneForm.valid;
  }

  checkRequiredOrderInactive() {
    for (const key in this.orderInactiveForm.controls) {
      if (this.orderInactiveForm.controls[key].errors) {
        this.orderInactiveForm.controls[key].setErrors({ 'forceRequired': true });
        this.orderInactiveForm.controls[key].markAsDirty();
      } else {
        this.orderInactiveForm.controls[key].updateValueAndValidity();
      }
    }
    return this.orderInactiveForm.valid;
  }


  async fnClickPrintDocument(data) {
    console.log('fnClickPrintDocument data', data);
    try {
      let filterData: any = '';
      if (data.filter) {
        filterData = {
          ...data.filter
        };
      }
      const checkUrl = this.common.checkMockupUrl('', '', filterData, {
        BASE_API: '',
        BASE_MODULE: environment.apiPrefix,
        BASE_RESOURCE: data.url + '/' + this.printOrderId
      });

      const resultCodeSuccess = environment.resultCodeSuccess;
      const response = await this.request.get(checkUrl.url, checkUrl.filter);
      if (response.resultCode === resultCodeSuccess) {
        const resultData = response.resultData || response.data;
        const fileName = resultData.filename;
        console.log('fileName', fileName);
        console.log(data)
          window.open(fileName);
      } else {
        this.openModal({
          'modalId': 'myModalError',
          'userTitle': response.resultCode,
          'userMessage': response.resultDescription,
          'userMessageList': []
        });
      }
    } catch (e) {
      console.log(e);
      const resultDescriptionSystemErrorTitle = environment.resultDescriptionSystemErrorTitle;
      const resultDescriptionSystemErrorMassage = environment.resultDescriptionSystemErrorMassage;
      this.openModal({
        'modalId': 'myModalError',
        'userTitle': resultDescriptionSystemErrorTitle,
        'userMessage': resultDescriptionSystemErrorMassage,
        'userMessageList': []
      });
    }
  }

  async fnClickPrintLabel(size) {
    try {
      const checkUrl = this.common.checkMockupUrl('', '', { size }, {
        BASE_API: '',
        BASE_MODULE: environment.apiPrefix,
        BASE_RESOURCE: environment.printSMLLabel + '/' + this.printOrderId
      });

      const resultCodeSuccess = environment.resultCodeSuccess;
      const response = await this.request.get(checkUrl.url, checkUrl.filter);
      if (response.resultCode === resultCodeSuccess) {
        const resultData = response.resultData || response.data;
        const fileName = resultData.filename;
        console.log('fileName', fileName);
        if (fileName) {
          window.open(fileName);
        }
      } else {
        this.openModal({
          'modalId': 'myModalError',
          'userTitle': response.resultCode,
          'userMessage': response.resultDescription,
          'userMessageList': []
        });
      }
    } catch (e) {
      console.log(e);
      const resultDescriptionSystemErrorTitle = environment.resultDescriptionSystemErrorTitle;
      const resultDescriptionSystemErrorMassage = environment.resultDescriptionSystemErrorMassage;
      this.openModal({
        'modalId': 'myModalError',
        'userTitle': resultDescriptionSystemErrorTitle,
        'userMessage': resultDescriptionSystemErrorMassage,
        'userMessageList': []
      });
    }
  }


  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  })

  goAlert(userTitle, userMessage, modalId) {
    const dataAlert = {
      'modalId': modalId,
      'userTitle': userTitle,
      'userMessage': userMessage
    };
    this.openModal(dataAlert);
  }

  // processFile(imageInput: any) {
  //   this.filePicture = null;
  //   const file: File = imageInput.files[0];
  //   const reader = new FileReader();
  //   reader.addEventListener('load', (event: any) => {
  //     console.log(file.type);
  //
  //     if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) === -1) {
  //       // this.goAlert("Validate", 'File should be .jpg, .jpeg, .png, .heic and .heif ', 'myModalError');
  //       this.goAlert('Invalid File Format', 'File should be .jpg, .jpeg, .png, .heic and .heif ', 'myModalWarning');
  //       return;
  //     }
  //
  //     if (file.size > 1000000) {
  //       // this.goAlert("Validate", 'The file is too large. Allowed maximum size is 1 MB.', 'myModalError');
  //       this.goAlert('Invalid File Format', 'The file is too large. Allowed maximum size is 1 MB.', 'myModalWarning');
  //       return;
  //     }
  //
  //     this.selectedFile = new ImageSnippet(event.target.result, file);
  //     this.selectedFile.pending = true;
  //     // let file = this.selectedFile.file
  //     console.log(file.name);
  //     this.imageInputText = file.name;
  //     this.toBase64(file).then((r) => {
  //       console.log(r);
  //       this.filePicture = r;
  //     });
  //   });
  //   reader.readAsDataURL(file);
  // }

  fnClickUploader() {
    if (this.imageList.length === 4) {
      return;
    }
    const elm: any = document.querySelector('.dx-fileuploader-button');
    elm.click();
  }

  fnDeleteImage(index) {
    this.imageList.splice(index, 1);
  }

  onValueChanged(event) {
    if (this.imageList.length > 4) {
      this.imageList.length = 4;
    }

    const removeIndexList = [];
    let isErrorFormat = false;
    let isErrorSize = false;
    for (let i = 0; i < this.imageList.length; i++) {
      const file: File = this.imageList[i];
      if (this.imageList[i].name.includes(".heic") || this.imageList[i].name.includes(".heif") || this.imageList[i].name.includes(".HEIC") || this.imageList[i].name.includes(".HEIF")) {
        let blob: Blob = file;
        let convProm: Promise<any>;

        console.log('before ::' +this.imageList[i].size)
        this.isLoadingPanel = true
        convProm = heic2any({ blob, toType: "image/jpeg", quality: 0.92 }).then((jpgBlob: Blob) => {

          //Change the name of the file according to the new format
          let newName = file.name.replace(/\.[^/.]+$/, ".jpg");

          //Convert blob back to file
          this.imageList[i] = this.blobToFile(jpgBlob, newName);

          this.common.fnResizeImage(this.imageList[i], newName, 0.20).then((resizeFile: File) => {
            // Convert blob back to file
            console.log('after ::' + resizeFile.size);
            if (resizeFile.size > 1000000) {
              removeIndexList.push(i);
              isErrorFormat = false;
              isErrorSize = true;
            }
            this.isLoadingPanel = false;
          });
        }).catch(err => {
          //Handle error
        });

      } else {
        if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(this.imageList[i].type) === -1) {
          removeIndexList.push(i);
          isErrorFormat = true;
          isErrorSize = false;

        } else {
          this.common.fnResizeImage(this.imageList[i], file.name, 0.20).then((resizeFile: File) => {
            if (resizeFile.size > 1000000) {
              removeIndexList.push(i);
              isErrorFormat = false;
              isErrorSize = true;
            }
          });
        }
      }
    }

    for (let i = removeIndexList.length - 1; i >= 0; i--) {
      this.fnDeleteImage(removeIndexList[i]);
    }


    if (isErrorFormat) {
      this.goAlert('Invalid File Format', 'File should be .jpg, .jpeg, .png, .heic and .heif ', 'myModalWarning');
    } else if (isErrorSize) {
      this.goAlert('Invalid File Size', 'The file is too large. Allowed maximum size is 1 MB.', 'myModalWarning');
    }

    // set input file null เพื่อให้อัพโหลดชื่อไฟล์เดิมซ้ำได้
    const elm: any = document.querySelector('input[type="file"]');
    elm.value = null;

  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;

    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;

    //Cast to a File() type

    return <File>theBlob;
  }

}

@NgModule({
  imports: [DxDataGridModule, CommonModule, FormsModule, ReactiveFormsModule, DxFileUploaderModule, NgxPrintModule,DxLoadPanelModule],
  exports: [CreateAlertComponent],
  declarations: [CreateAlertComponent]

})
export class CreateAlertModule {
}
